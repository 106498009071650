import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom"; // Import useNavigate and useParams
import axios from "axios";

const Location: React.FC = () => {
  // Define the interface for location objects
  interface Location {
    id: number;
    name: string;
    image: string;
  }

  const [locations, setLocations] = useState<Location[]>([]); // State for storing locations
  const [loading, setLoading] = useState<boolean>(false); // To handle loading state
  const [error, setError] = useState<string>(""); // To handle errors
  const apiRoute = "https://api.fresh-propertymanagement.com/public-category"; // API route
  // const apiRoute = "https://api.fresh-propertymanagement.com/public-category"; // API route
  const navigate = useNavigate(); // React Router's navigation hook
  const { id } = useParams<{ id: string }>(); // Capture id from the URL

  useEffect(() => {
    const fetchLocations = async () => {
      setLoading(true); // Start loading
      try {
        const url = id ? `https://api.fresh-propertymanagement.com/public-category/${id}` : apiRoute; // Adjust API route based on id
        // const url = id ? `https://api.fresh-propertymanagement.com/public-category/${id}` : apiRoute; // Adjust API route based on id
        const response = await axios.get(url); // Await the API call
        setLocations(response.data); // Set state with the data
      } catch (error) {
        setError("Error fetching locations.");
        console.error("Error fetching locations:", error); // Handle errors
      } finally {
        setLoading(false); // Stop loading
      }
    };

    fetchLocations(); // Call the async function
  }, [id]); // Empty dependency array ensures this runs once on component mount, re-runs when 'id' changes

  const handleNavigation = (locationId: number) => {
    // Navigate to the category page with the location ID
    navigate(`/blogs/${locationId}`);
  };

  return (
    <div style={styles.container}>
      {loading && <p>Loading...</p>} {/* Show loading message */}
      {error && <p>{error}</p>} {/* Show error message */}
      <div style={styles.grid}>
        {locations.map((location) => (
          <div
            style={styles.gridItem}
            key={location.id}
            onClick={() => handleNavigation(location._id)} // Add click handler
          >
            <div style={styles.imageWrapper}>
              <img
                src={`https://api.fresh-propertymanagement.com/` + location.image}
                alt={location.name}
                style={styles.image}
              />
              <div style={styles.overlay}>
                <h3 style={styles.name}>{location.name}</h3>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

// Define styles as an object
const styles = {
  container: {
    padding: "20px",
    textAlign: "center" as const,
    fontFamily: "Arial, sans-serif",
  },
  heading: {
    fontSize: "24px",
    marginBottom: "20px",
    color: "#333",
  },
  grid: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
    gap: "20px",
  },
  gridItem: {
    position: "relative" as const,
    cursor: "pointer",
    borderRadius: "8px",
    overflow: "hidden",
    transition: "transform 0.3s ease",
  },
  imageWrapper: {
    position: "relative" as const,
    width: "100%",
    height: "100%",
  },
  image: {
    width: "100%",
    height: "200px",
    objectFit: "cover" as const,
    filter: "brightness(70%)",
    transition: "filter 0.3s ease, transform 0.3s ease",
  },
  overlay: {
    position: "absolute" as const,
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.4)",
    transition: "opacity 0.3s ease",
  },
  name: {
    fontSize: "18px",
    color: "#fff",
    textAlign: "center" as const,
  },
};

export default Location;
